export const data = [
  {
    link: {
      name: "About",
      to: "/",
    },
  },
  {
    link: {
      name: "Products",
      to: "/products",
    },
  },
  {
    link: {
      name: "Contacts",
      to: "/contacts",
    },
  },
];
