import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  text-transform: uppercase;
  margin: 0.8rem 0;
  padding: 0.8rem;
  text-decoration: none;
  color: #f7f5ef;
  font-size: 1.6rem;
  font-weight: 600;
  transition: 0.5s;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid #aa363f;
    border-bottom: 2px solid #aa363f;
    transform: scaleY(2);
    opacity: 0;
    transition: 0.3s;
  }
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #aa363f;
    transform: scale(0);
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
  }
  ${({ $active }) =>
    $active
      ? css`
          &:before {
            transform: scaleY(1);
            opacity: 1;
          }
          &:after {
            transform: scaleY(1);
            opacity: 1;
          }
        `
      : css`
          &:hover {
            color: #fff;
            &:before {
              transform: scaleY(1);
              opacity: 1;
            }
            &:after {
              transform: scaleY(1);
              opacity: 1;
            }
          }
        `};

  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin: 0.4rem 0;
    padding: 0.6rem 0.4rem;
  }
`;
export const NavLink = ({ link }) => {
  const [isActive, setIsActive] = useState(false);

  // eslint-disable-next-line no-undef, no-restricted-globals
  const currentPage = typeof location !== "undefined" && location.pathname;

  useEffect(() => {
    if (currentPage === link.to || currentPage === `${link.to}/`) {
      setIsActive(true);
    }
    return () => {
      setIsActive(false);
    };
  }, [currentPage]);

  return (
    <StyledLink to={link.to} $active={isActive}>
      {link.name}
    </StyledLink>
  );
};
