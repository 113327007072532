import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    scroll-behavior: smooth;
  }

  html {
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  }

  body {
    box-sizing: border-box;
    color: #e6e7e8;
    font-family: Calibri, sans-serif;
  }
`;
