import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { NavLink } from "./components/NavLink";
import { data } from "./Data/data";

const HeaderWrapper = styled.header`
  background: black;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 10;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 136rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 3.2rem;

  @media (max-width: 600px) {
    padding: 1.6rem 0.8rem;
  }
`;

const NavContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4rem;

  @media (max-width: 600px) {
    grid-gap: 0.8rem;
  }
`;

const LogoWrapper = styled(Link)`
  @media (max-width: 600px) {
    max-width: 7.5rem;
    width: 100%;
  }
`;

const StyledLogo = styled(Img)`
  @media (max-width: 600px) {
    max-width: 7.5rem;
    width: 100%;
    img {
      max-width: 7.5rem;
      width: 100%;
      margin-right: 2.4rem;
      object-fit: contain !important;
    }
  }
`;

const Header = () => {
  const image = useStaticQuery(graphql`
    query {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <LogoWrapper to="/">
          <StyledLogo fixed={image.file.childImageSharp.fixed} />
        </LogoWrapper>
        <NavContainer>
          {data.map(({ link }) => (
            <NavLink key={link.name} link={link} />
          ))}
        </NavContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
export default Header;
