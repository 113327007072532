import React from "react";
import styled from "styled-components";

import bgVideoMp4 from "media/video/videoBg.mp4";
import bgVideoWebm from "media/video/videoBg.webm";
import { ContextProvider } from "utils/Context";
import { GlobalStyles } from "./GlobalStyles";
import Header from "../Header";
import Footer from "../Footer";

const LayoutContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Video = styled.video`
  position: fixed;
  right: 0;
  top: 7rem;
  min-width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  opacity: 0.7;
  overflow: hidden;

  @media (max-width: 600px) {
    right: -90%;
  }
`;

const Layout = ({ children }) => (
  <ContextProvider>
    <LayoutContainer>
      <Video autoPlay loop muted>
        <source src={bgVideoWebm} type="video/mp4" />
        <source src={bgVideoMp4} type="video/mp4" />
        Sorry, but your browser does not support this video file.
      </Video>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutContainer>
  </ContextProvider>
);

export default Layout;
