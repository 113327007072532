import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
  color: #626568;
`;

const FooterWrapper = styled.div`
  background: #1d1d1e;
  margin-top: auto;
  width: 100%;
  padding: 1.6rem 4rem;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "footer" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                footer
              }
            }
          }
        }
      }
    }
  `);

  const { footer } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  return (
    <FooterWrapper>
      <Container>{footer}</Container>
    </FooterWrapper>
  );
};

export default Footer;
