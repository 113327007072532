import React, { useState, createContext } from "react";

export const Context = createContext({
  selectedSubBrand: null,
  setSelectedSubBrand: () => null,
  selectSubBrand: () => null,
});

export const ContextProvider = ({ children }) => {
  const [selectedSubBrand, setSelectedSubBrand] = useState("");

  const selectSubBrand = (subBrand) => {
    setSelectedSubBrand(subBrand);
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      document.getElementById("sub-brand").scrollIntoView();
    }, 100);
  };

  return (
    <Context.Provider
      value={{
        selectedSubBrand,
        setSelectedSubBrand,
        selectSubBrand,
      }}
    >
      {children}
    </Context.Provider>
  );
};
